<template>
  <div>
    <detail-header></detail-header>
    <div style="margin:20px;">
      <el-button type="primary" @click="doExport">导出资产明细</el-button>
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName"
      ></el-table-column>

      <el-table-column label="电话" prop="phone" align="right"></el-table-column>
      <el-table-column label="变动天数" prop="days" align="right"></el-table-column>
      <el-table-column label="剩余天数" prop="afterDays" align="right"></el-table-column>
      <el-table-column label="变更原因" prop="remark"></el-table-column>
      <el-table-column label="类型" prop="detailType" align="right">
        <template #default="scope">
          <eb-badge
            :list="this.const.AGENT_DETAIL_TYPE"
            :target="scope.row.detailType"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createAt"></el-table-column>

    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { agentAssetDetail ,exportAgentAssetDetail } from "@/api/agent";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList;
    },
  }),
  data() {
    return {
      dataSource: [],
      listQuery: {
      
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    doExport(){
      exportAgentAssetDetail({
        operationId : this.id,
        phone: this.phone,
      }).then(res=>{
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.phone}代理资产明细.xlsx`
        );
      })
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      agentAssetDetail({ 
        operationId : this.id,
        phone: this.phone,
        ...this.pages
       }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    let params = this.$router.currentRoute.value.params;
    this.id = params.id;
    this.phone = params.phone;

    this.$store.dispatch("fetchAgentListAll").then((res) => {
      this.getList();
    });
  },
};
</script>
